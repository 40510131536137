.modal-termos {
  overflow-y: scroll;
  height: 700px;
}

.term-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  font-weight: bold;
}

.term-body {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 100;
}
.btn-fechar {
  border: none;
  width: 30%;
  height: 40px;
  border-radius: 6px;
  background-color: #442c75;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  margin: 8px 30px 8px;
  align-self: flex-end;
}

.MuiFilledInput-root {
  border-radius: 4px;
}
body a {
  color: #442c75;
}
body a:hover {
  color: #442c75;
}
body .hefpVu > div {
  border: 1px solid #442c75;
}
body .hefpVu > div ul li {
  border-bottom: 1px solid #442c75;
}
